import React, { useState, useEffect, useContext } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Helmet from "react-helmet";
import { navigate } from "gatsby";

// Context
import { FooterColor } from "../components/context/footer-color";

// Components
import { Page, AccountContainer } from "../components/trade/trade-components";
import {
	Fields,
	ContentContainer,
} from "../components/account/login-components";

// Styles
import { tertiary } from "../components/utils/colors";

const CUSTOMER_ACTIVATE_ACCOUNT = gql`
	mutation($activationUrl: URL!, $password: String!) {
		customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
			customer {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

const ActivateCustomer = ({ location }) => {
	const [newPasswordInput, setNewPasswordInput] = useState(null);
	const [activationUrl, setActivationUrl] = useState(null);
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		let url = new URL(location.href);
		let query = url.searchParams.get("activation_url");
		setActivationUrl(query);
	}, [location]);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	return (
		<>
			<Mutation mutation={CUSTOMER_ACTIVATE_ACCOUNT}>
				{(customerActivateByUrl) => {
					return (
						<Page>
							<Helmet title={`Activate Account | Christian Watson`} />

							<AccountContainer>
								<ContentContainer>
									<section>
										<h1>Activate Account</h1>
										<Fields>
											<label>
												Please enter a password to activate your account. You
												will be automatically redirected to the account page
												once your password has been set.
											</label>
											<br /> <br />
											<input
												desktopWidth={`calc(50% - 20px)`}
												className="input"
												value={newPasswordInput}
												type="password"
												onChange={(e) => setNewPasswordInput(e.target.value)}
												placeholder="New Password"
												required
											/>
											<button
												// disabled={
												// 	activationUrl !== null || newPasswordInput === null
												// }
												onClick={(e) => {
													e.preventDefault();
													customerActivateByUrl({
														variables: {
															activationUrl: activationUrl,
															password: newPasswordInput,
														},
													}).then((result) => {
														navigate(`/account/login/`);
													});
												}}
											>
												Submit Password
											</button>
										</Fields>
									</section>
								</ContentContainer>
							</AccountContainer>
						</Page>
					);
				}}
			</Mutation>
		</>
	);
};

export default ActivateCustomer;
