import React from "react";
import styled from "styled-components";

// Styles
import { secondaryDark, tertiary, secondaryLight } from "../utils/colors";

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	max-width: 830px;
	margin: 0 auto;
	padding: 0;

	& section {
		max-width: 370px;
		width: 100%;

		@media (max-width: 800px) {
			max-width: 400px;

			&.account-login,
			&.forgot-password {
				margin: 0 0 70px 0;
			}
		}

		@media (max-width: 500px) {
			max-width: 100%;
		}
	}

	& h1 {
		margin: 0 auto;

		font-size: 16px;
		line-height: 125%;

		@media (max-width: 768px) {
			margin: 0 auto 14px auto;
		}
	}

	& p.password-reset {
		margin: 22px 0 14px 0;
	}

	& .privacy-policy-text,
	& a {
		color: ${secondaryDark};
	}

	& .privacy-policy-text {
		margin: 14px 0 28px 0;
	}
`;

export const Fields = styled.div`
	margin: 15px 0 0 0;

	& .field {
		line-height: 1;
	}

	& input {
		border: 0;
		color: ${secondaryDark};
		border-bottom: 1px solid ${secondaryLight};

		width: 100%;

		margin: 0 0 14px 0;
		padding: 7px 0;

		// &:last-of-type {
		// 	margin: 0;
		// }

		::-webkit-input-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;
			color: ${secondaryLight};
		}
		::-moz-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;
			color: ${secondaryLight};
		}
		:-ms-input-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;
			color: ${secondaryLight};
		}
		:-moz-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;
			color: ${secondaryLight};
		}
		::placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;
			color: ${secondaryLight};
		}
	}

	& .user-signup-message > p {
		white-space: pre-line;
	}

	& .checkbox {
		color: ${secondaryDark};

		& input {
			width: auto;
			margin: 0 5px 0 0;
		}

		& label {
			line-height: 14px;
		}
	}

	& .password-reset-message > p {
		margin: 0 0 15px 0;
	}

	& .reset-password-button {
		margin: 14px 0 28px 0;
	}

	& .login-error-message {
		width: 100%;

		margin: 9px 0 0 0;
		padding: 0;

		color: ${secondaryDark};
	}

	& .forgot-password {
		color: ${secondaryLight};
		margin: 14px 0 28px 0;

		&:hover {
			color: ${secondaryDark};
		}
	}
`;

export const RegisterFormHeader = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	& p {
		margin: 0;
	}
`;

export const FormSelectContainer = styled.div`
	& button {
		&:first-of-type {
			margin: 0 15px 0 0;
		}

		border-bottom: 1px solid transparent;

		&:hover,
		&:focus {
			color: ${secondaryDark} !important;
			border-bottom: 1px solid ${secondaryDark};
		}
	}

	& .active {
		border-bottom: 1px solid ${secondaryDark};
	}
`;

export const OptionsContainer = styled.div`
	width: 110px;

	& .account-registration-options__control {
		border-color: inherit;
		border: 0;
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;
		min-height: auto;

		&:hover {
			border-color: inherit;
		}
	}

	& .account-registration-options__menu-list {
		padding: 0;
	}

	& .account-registration-options__single-value {
		color: inherit;
	}

	& .account-registration-options__single-value {
		margin-left: 0;
	}

	& .account-registration-options__value-container {
		padding: 0;
	}

	& .css-tlfecz-indicatorContainer {
		color: inherit;
		padding: 0;
	}

	& .account-registration-options__indicator {
		padding: 0;
	}

	& .account-registration-options__placeholder {
		color: inherit;
		padding-left: 0;

		margin-left: 0;
		margin-right: 0;
	}

	& .account-registration-options__indicator-separator {
		display: none;
	}

	& .account-registration-options__menu {
		border-radius: 0;

		& .account-registration-options__option--is-focused {
			background-color: #f5f5f5;
		}

		& .account-registration-options__option--is-selected {
			background-color: ${tertiary};
			color: inherit;
		}
	}

	& .css-b8ldur-Input {
		margin: 0;
	}
`;

export const SignupMessage = styled.div`
	margin: 32px 0 0 0;
`;
